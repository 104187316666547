<template>
  <TTView>
    <VRow>
      <VCol cols="1">
        <TTBtn
          color="tt-light-mono-100"
          icon
          :to="{
            name : Names.R_TALENT_DASHBOARD
          }"
        >
          <VIcon>$prev</VIcon>
        </TTBtn>
      </VCol>
      <VCol>
        <h1>{{ $t('HistorydataUpload.header') }}</h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VForm
          ref="form"
          v-model="isValid"
        >
          <VRow>
            <VCol>
              <label>{{ $t('HistorydataUpload.file') }}</label>
              <VFileInput
                v-model="file"
                class="mt-3 file-input-background"
                accept=".csv"
                :rules="[customRules.fileRequired]"
                outlined
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <label>{{ $t('HistorydataUpload.separator') }}</label>
              <VSelect
                v-model="separator"
                :items="separatorItems"
                filled
                dense
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <TTTextField
                v-model="email"
                label="Email"
                :rules="[rules.required]"
                clearable
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <TTBtn
                :loading="loading"
                :disabled="!isValid || loading"
                @click="submit"
              >
                {{ $t('HistorydataUpload.uploadBtn') }}
              </TTBtn>
            </VCol>
          </VRow>
        </VForm>
      </VCol>
    </VRow>
    <VRow v-if="error">
      <VCol>
        <label>{{ $t('HistorydataUpload.error') }}</label>
        <TTTextField
          v-model="error"
          dissabled
        />
      </VCol>
    </VRow>
  </TTView>
</template>
<script>
import { required } from '@front.backoffice/common';

export default {
  name: 'HistorydataUpload',
  inject: ['Names'],
  data() {
    return {
      file: null,
      isValid: false,
      loading: false,
      separatorItems: [',', ';'],
      separator: ',',
      error: null,
      email: '',
      customRules: {
        fileRequired: (v) => (!!v && this.isCsvFile(v)) || 'Обязательно csv файл',
      },
    };
  },
  computed: {
    rules() {
      return {
        required: required(),
      };
    },
  },
  methods: {
    isCsvFile(v) {
      // NOTE: проверка на тип файла
      return v.type === 'text/csv';
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          this.error = null;
          const { url, id } = await this.$di.api.Assessment.PrepareUrl();
          const data = {
            url,
            separator: this.separator,
            name: id,
            email: this.email,
            mimetype: this.file.type,
            extension: this.file.name.split('.').pop(),
          };

          await this.cloudUpload(url);
          await this.$di.api.Assessment.DownloadFile({ data });

          this.$di.notify.snackSuccess(this.$t('HistorydataUpload.resultSuccess'));
        } catch (error) {
          this.error = JSON.stringify(error.response.data);
          this.$di.notify.errorHandler(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async cloudUpload(uploadUrl) {
      try {
        /**
       * Note: Content-Type всегда MIME тип файла
       * @see https://cloud.yandex.ru/docs/storage/concepts/presigned-post-forms#form-fields Описание полей формы
       * */
        const headers = {
          'Content-Type': this.file.type,
        };

        const config = {
          method: 'PUT',
          body: this.file,
          headers,
        };

        /**
       * Note: Запрос не работает из-за CORS.
       * Проверить работоспособность запроса можно отключив CORS в браузере или на stage/production окружении
       * */
        /** Note: Здесь используется простой fetch */
        await this.$di.api.CloudStorage
          .fileStorageUpload(uploadUrl, config);
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },

};
</script>
<style lang="scss" scoped>
.file-input-background {
  background:  map-get($tt-light-mono-4, 'base');
}
</style>
